/* eslint-disable prettier/prettier */
import PageHeader from 'components/common/PageHeader';
import React, { useState, useEffect } from 'react';
import { Card, Breadcrumb, Row, Col, Form, Modal,Button  } from 'react-bootstrap';
import { faPlus, faChartBar, faTrash, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import WifaService from 'services/wifa-service';
import AdvanceTableSearchBoxPlug from '../../components/common/advance-table/AdvanceTableSearchBoxPlug';
import FalconCloseButton from 'components/common/FalconCloseButton';
import WIFAScenarioViewer from 'pages/kpi/wifa-scenario-viewer';
import { Helmet } from 'react-helmet';
import './wifaHome.css';

const WIFAHome = () => {
    const [bloodCenterDivision, setBloodCenterDivision] = useState([]);
    const [scenarioData, setScenarioData] = useState([]);
    const [product, setProduct] = useState('');
    const [bloodCenterCode, setBloodCenterCode] = useState('');
    const [informationModalVisible, setInformationModalVisible] = useState(false);
    const [scenarioId, setScenarioId] = useState(0);
    const [scenarioName, setScenarioName] = useState('');
    const [availableSession, setAvailableSession] = useState([]);
    const [wifaParam] = useState('ScenarioKey');
    const [wifaPath] = useState('/Inventory/Platelet Projection Scenario');
    // const [publishedBy, setPublishedBy] = useState('');
    // const [publishedOn, setPublishedOn] = useState('');

    const columns = [
        {
          accessor: 'scenarioKey',
          Header: 'Scenario Id',
        },
        {
          accessor: 'scenarioName',
          Header: 'Scenario Name',
          headerProps: { className: 'pe-7' }
        },
        {
          accessor: 'scenarioDesc',
          Header: 'Scenario Description'
        //  headerProps: { className: 'pe-7' }
        },
        {
          accessor: 'scenarioNotes',
          Header: 'Notes'
        },
        {
            accessor: 'scenarioCategoryName',
            Header: 'Product'
        },
        {
            accessor: 'recCreatedDTM',
            Header: 'Published On'
        },
        {
          accessor: 'recCreatedBy',
          Header: 'Published By',
        },
        {
          accessor: 'isPrimaryScenario',
          Header: 'Is Primary'
        },
        {
            accessor: 'none',
            Header: '',
            // eslint-disable-next-line
             Cell: rowData => (
            //   <Link
            //   to={`#`}
            //   className="text-primary fw-semi-bold"
            // >
            //   Launch
            //</Link>
                  <Button type="button" variant="outline-primary" className="me-1 mb-1" size="sm"  
                          onClick={() => onClickLaunch(
                            rowData.row.original.scenarioKey, 
                            rowData.row.original.scenarioName
                            // rowData.row.original.recCreatedBy,
                            // rowData.row.original.recCreatedDTM
                          )}
                        >
                          <FontAwesomeIcon icon={faChartBar} />
                        </Button>
             )
        }
      ];

      const columSession = [
        {
          accessor: 'scenarioKey',
          Header: 'Scenario Id',
        },
        {
          accessor: 'bloodCenterCode',
          Header: 'Blood Center',
        },
        {
          accessor: 'wifaSessionId',
          Header: 'WIFA Session',
          headerProps: { className: 'pe-7' }
        },
        {
          accessor: 'dailyNotes',
          Header: 'Notes'
        },
        {
            accessor: 'recCreatedDTM',
            Header: 'Created On'
        },
        {
            accessor: 'empty',
            Header: '',
            // eslint-disable-next-line
             Cell: rowData => (
              <Link to={'/plateletWIFA/WIFAWizardLanding'} className="btn btn-outline-primary" size="sm"  
              onClick={() => onClickResumeSession(
                rowData.row.original.wifaSessionId
              )}
            >
              <FontAwesomeIcon icon={faPlay} /> Resume
            </Link>
             )
        },
        {
            accessor: 'none',
            Header: '',
            // eslint-disable-next-line
             Cell: rowData => (
              <Button type="button" variant="outline-danger" className="me-1 mb-1" size="sm"  
              onClick={() => onClickDeleteSession(
                rowData.row.original.wifaSessionId
              )}
            >
              <FontAwesomeIcon icon={faTrash} /> Delete
            </Button>
             )
        }
      ]
      useEffect(() => {
        WifaService.getDivisions()
          .then((divisions) => {
            setBloodCenterDivision(divisions);
            WifaService.getAvailableSession()
            .then((availableSession) => {
              setAvailableSession(availableSession);
            });
          })
          
      }, []);
    const handleProductChange = e => {
      setProduct(e.target.value);
    };
    const handleDivisionChange = e => {
      var bloodCenterCodeArray = bloodCenterDivision.filter(x => x.bloodCenterKey == e.target.value);
      if (bloodCenterCodeArray.length > 0) {
        setBloodCenterCode(bloodCenterCodeArray[0].bloodCenterCode);
      }
        WifaService.getAvailableScenarios(product, e.target.value)
          .then((scenario)=> {
            setScenarioData(scenario);
          })
    };

    // function onClickLaunch (scenarioId, scenarioName, publishedBy, publishedOn) {
    function onClickLaunch (scenarioId, scenarioName) {
      setInformationModalVisible(true);
      setScenarioId(scenarioId);
      setScenarioName(scenarioName);
      // setPublishedBy(publishedBy);
      // setPublishedOn(publishedOn);
    }

    function onClickDeleteSession (wifaSessionId) {
      WifaService.deleteWifaSession(wifaSessionId)
      .then(() => {
        WifaService.getAvailableSession()
            .then((availableSession) => {
              setAvailableSession(availableSession);
            });
      });
    }

    function onClickResumeSession (wifaSessionId) {
      WifaService.deleteWifaSession(wifaSessionId);
    }

  return (
    <>
      <Helmet>
        <title>Inventory Projection Model</title>
      </Helmet>
    <PageHeader
          title="Inventory Projection Model"
          className="mb-2"
          col="12"
        >
          <Row>
            <Col className="text-start mt-1">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Inventory Projection Model
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </PageHeader>
        <Card className="h-100">
            {/* <Card.Header> </Card.Header> */}
            <Card.Body>
              <Row className="mb-3 g-3">
                <Col lg={10}>
                    <p>
                       The Platelet Supply and Demand What-If-Analysis allows you the ability to evaluate various scenarios that may impact inventory levels for the
                        projected time period of 14 days. 
                    </p>
                </Col>
                {/* <Col xs="auto" sm={3} lg={2} className="text-end mt-4">
                <Link
                  className="btn btn-outline-primary"
                  to={'/plateletWIFA/WIFAHistory'}
                >
                  <FontAwesomeIcon icon={faHistory} /> 
                  <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />WiFA History
                </Link>
              </Col> */}
              </Row>
              <Row>
                <Form.Group as={Col} lg={4}>
                    <Form.Label>Select Product</Form.Label>
                    <Form.Select
                      name="Data Domain"
                      size="sm"
                      //value={tables}
                      onChange={handleProductChange}
                      className="me-2"
                    >
                      <option value="">Select</option>
                      <option value="Platelet">Platelet </option>
                      <option value="RBC">RBC </option>
                      {/* {data.map(i => (
                        <option key={i.tableConfigId} value={i.tableConfigId}>
                          {i.tableName}
                        </option>
                      ))} */}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group as={Col} lg={4}>
                    <Form.Label>Select Division</Form.Label>
                    <Form.Select
                      name="Action"
                      size="sm"
                      //value={tables}
                      onChange={handleDivisionChange}
                      className="me-2"
                    >
                      <option>Select</option>
                      {bloodCenterDivision.map(i => (
                        <option key={i.bloodCenterKey} value={i.bloodCenterKey}>
                          {i.bloodCenterCode}
                        </option>
                      ))}
                      {/* <option> NYBC </option>
                      <option> CBC </option>
                      <option> IBR </option>
                      <option> RIBC </option>
                      <option> BBD </option> */}
                    </Form.Select>
                  </Form.Group>
                  <Col xs="auto" sm={3} lg={4} className="text-end mt-4">
                <Link
                  className="btn btn-outline-primary"
                  to={'/plateletWIFA/WIFAWizardLanding'}
                >
                  <FontAwesomeIcon icon={faPlus} /> New WIFA
                </Link>
              </Col>
                </Row>

            </Card.Body>
            <Card.Footer>

            </Card.Footer>
        </Card>
        <Card className="h-100 mb-2 mt-2">
        <AdvanceTableWrapper
                columns={columSession}
                data={availableSession}
                //selection
                sortable
                pagination
                perPage={10}
                rowCount={availableSession.length}
              >
          <Card.Header>
            Today's available session
            <Row className="flex-between-center mt-3">
              <Col xs={9} sm={6} lg={3}>
                <AdvanceTableSearchBoxPlug table placeholder="Search" />
              </Col>
              <Col xs="auto" sm={3} lg={3} className="text-end marginTop">
                
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            {/* {outputData.length > 0 && ( */}

                <Card>
                  <Card.Body className="p-0 overflow-auto">
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                  </Card.Body>
                  <Card.Footer>
                            <AdvanceTableFooter
                      rowCount={availableSession.length}
                      table
                      rowInfo
                      navButtons
                    />
                  </Card.Footer>
                </Card>

            {/* )} */}
          </Card.Body>
          </AdvanceTableWrapper>
        </Card>
        <Card className="h-100 mb-2 mt-2">
        <AdvanceTableWrapper
                columns={columns}
                data={scenarioData}
                //selection
                sortable
                pagination
                perPage={10}
                rowCount={scenarioData.length}
              >
          <Card.Header>
            Available Scenarios (Last 12 Months)
            <Row className="flex-between-center mt-3">
              <Col xs={9} sm={6} lg={3}>
                <AdvanceTableSearchBoxPlug table placeholder="Search" />
              </Col>
              <Col xs="auto" sm={3} lg={3} className="text-end marginTop">
                
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            {/* {outputData.length > 0 && ( */}

                <Card>
                  <Card.Body className="p-0 overflow-auto">
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                  </Card.Body>
                  <Card.Footer>
                            <AdvanceTableFooter
                      rowCount={scenarioData.length}
                      table
                      rowInfo
                      navButtons
                    />
                  </Card.Footer>
                </Card>

            {/* )} */}
          </Card.Body>
          </AdvanceTableWrapper>
        </Card>

        <Modal dialogClassName="modal-width" contentClassName="modal-height" size="lg" show={informationModalVisible}>
        <Modal.Header>
          <h5 className="modal-title">{bloodCenterCode} {scenarioName}</h5> <br/>
          {/* <p>Created By: {publishedBy} | Created On: {publishedOn} </p> */}
          <FalconCloseButton onClick={() => setInformationModalVisible(false)} />
        </Modal.Header>
        <Modal.Body>
             <WIFAScenarioViewer 
              wifaParamId = {scenarioId}
              wifaPath = {wifaPath}
              wifaParam = {wifaParam}
            />
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            type="button"
            className="btn btn-primary"
            onClick={() => setInformationModalVisible(false)}
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
        </>
    )    
}

export default WIFAHome;
